import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// eslint-disable-next-line import/no-cycle
import { prepareHeaders } from './utils';

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseWebServer = createApi({
    reducerPath: 'webServer',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_MYO_SERVER}`, prepareHeaders
    }),
    tagTypes: ['User', 'Giftoin', 'Giftoins', 'Network', 'Coin', 'Product', 'Abi', 'Contract',],
    endpoints: () => ({}),
})