import { TextField, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import { validateEmail } from 'src/helpers/starter-edition/validation';
import useLoginWithEmailAndPasscode from 'src/hooks/auth/useLoginWithEmailAndPasscode';
import { useAppDispatch } from 'src/redux/store';



export default function AuthWithEmailAndPasscode() {
  const [email, setEmail] = useState<string>();
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { loginWithEmailAndPasscode } = useLoginWithEmailAndPasscode();

  const handleContinueWithEmail = () => {
    const noSpacesEmail = email?.split(' ').join('');
    if (noSpacesEmail && validateEmail(noSpacesEmail)) {
      setError(false);
      // navigate('/verify', {
      //   state: {
      //     email
      //   }
      // });
      loginWithEmailAndPasscode(noSpacesEmail, '123456');
    } else {
      setError(true);
    };
  };


  return (
    <>
      <TextField id="outlined-basic" error={error} helperText={error && 'Email is not valid.'} label="Email Address" variant="outlined" sx={{ width: '100%' }} onChange={(e) => setEmail(e.target.value)} />
      <Button variant='contained' size='large' sx={{ width: '100%' }} onClick={handleContinueWithEmail}>Continue</Button>
    </>
  );
};
