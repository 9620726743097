import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, User } from "firebase/auth";
import { useSnackbar } from "notistack"
import { FIREBASE_API } from "src/config-global";
import { initialize, initializeBalance, login, web2Login } from "src/redux/slices/authSlice";
import { dispatch, store, useAppDispatch } from "src/redux/store";
import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { setAuthEmail, setAuthEmailValidUser, setEmail, setEmailAndPasscodeLogin, setHolderId, setLoggedIn, setName, setUserName } from "src/redux/slices/starterEditionSlice";
import { useState } from "react";
import { userAuth } from "src/api/userAuth";
import { extractStatusObjectFromReduxResponse } from "src/utils/errors";

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

export default function useLoginWithEmailAndPasscode() {
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const appDispatch = useAppDispatch();

  const loginWithEmailAndPasscode = async (email: string, passcode: string) => {
    try {
      setIsLoading(true);

      const result: any = await appDispatch(userAuth.endpoints.loginNew.initiate({ email, passcode }));

      console.log(result);

      if (result.error) {
        const errorStatus = extractStatusObjectFromReduxResponse(result.error);
        if (errorStatus.errorCode === 500) {
          throw new Error("Communication error with server - please try again later.");
          return false;
        };
        if (result.error.status === 404) {
          // throw new Error("User not in whitelist.");
          enqueueSnackbar(`User not in whitelist.`, { variant: 'warning', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })
          return false;
        };
      };

      // setSession(firebaseIdToken);

      store.dispatch(setHolderId({ id: email }));


      store.dispatch(web2Login({ user: { email } }));

      // initializing user balance 
      if (result.data.fiatInDollar >= 0 && result.data.giftoins >= 0) {
        const balance = result.data as { fiatInDollar: number; giftoins: number; };

        appDispatch(initializeBalance(balance));
      };

      dispatch(setEmailAndPasscodeLogin({ emailAndPasscodeLogin: true }));
      dispatch(setAuthEmailValidUser({ valid: true }));
      dispatch(setAuthEmail({ emailAddress: email }));

      setIsLoading(false);
      return true;
    }
    catch (error) {
      enqueueSnackbar(`Failed to login -${error.message} `, { variant: 'error' })
      setIsLoading(false);
    };
    return true;
  };

  return { loginWithEmailAndPasscode, isLoading }
};