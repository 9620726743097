// ----------------------------------------------------------------------
// @mui
import { Alert, Box, Button, ButtonProps, IconButton, Stack } from '@mui/material';
import { useState } from 'react';
import useLoginWithGoogle from 'src/hooks/auth/useLoginWithGoogle';
import { useAppDispatch } from 'src/redux/hooks';
// components
import Iconify from '../../components/iconify';


interface Props extends ButtonProps {
  text?: string;
}

export default function AuthWithSocial({ text = undefined, ...other }: Props) {
  const [errorLogin, setErrorLogin] = useState<undefined | string>(undefined);
  const dispatch = useAppDispatch();

  const { loginWithGoogle } = useLoginWithGoogle();

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle();


    } catch (error) {
      console.log(error)
      setErrorLogin(error.message);
    }
  };

  return (
    <Box sx={{width: '100%'}}>

      <Stack direction="row" justifyContent="center" spacing={2} sx={{width: '100%'}}>
        <Button {...other} variant='soft' onClick={handleGoogleLogin} startIcon={<Iconify icon="eva:google-fill" color="#DF3E30" />}>
          { text || 'Login With Google' }
        </Button>
      </Stack>
      {errorLogin && <Alert severity="error">{errorLogin}</Alert>}

    </Box>
  );
}
