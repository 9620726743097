/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomFile } from "src/components/upload";
import { FirebaseEnums } from "src/types/firebase";
import { OrderInterfaces } from 'src/types/order';
import { StarterEditionEnums, StarterEditionInterface } from "src/types/starter-edition";
import { RefreshAction } from "src/types/starter-edition/enums";
import _mock_ from "src/_mock/templates";
import { validateEmail } from "../../helpers/starter-edition/validation";




const DEFAULT_RECIPIENT_INFO = {
  recipientName: '',
  recipientEmail: '',
  greeting: '',
  message: ''
};

const DEFAULT_CONFIRMATION_INFO = {
  name: '',
  tosChecked: false,
  unauthorizedCountryTosChecked: false,
  loggedIn: false,
  email: '',
  holderId: '',
};

const MOBILE_DEFAULT_ASSETS = [
  'Bitcoin',
  'Ethereum',
];

const DEFAULT_COMPLETION = {
  orderId: '',
  shareWithEmails: [],
  shared: false,
  removeProductOverview: false,
  orderStatus: StarterEditionEnums.Status.InProgress,
  loading: true,
  error: false,
  numOfSync: 0,
  overallSyncs: 0,
};

const DEFAULT_AUTH_WITH_EMAIL = {
  email: '',
  validUser: false,
};

// eslint-disable-next-line radix
const MAX_NUM_OF_SYNC = parseInt((process.env.MAX_NUM_OF_SYNC ?? '5'));

// eslint-disable-next-line radix
const MAX_OVERALL_SYNCS = parseInt((process.env.MAX_OVERALL_SYNCS ?? '20'));


interface StarterEditionState {
  assetsERC20: OrderInterfaces.AssetERC20[],
  stepper: StarterEditionInterface.Stepper,
  assetERC721: OrderInterfaces.AssetERC721[];
  erc721templateSelected: number | null;
  erc721File: File | CustomFile | string | null;
  mobileSelectedAssetsERC20: string[];
  wrapFile: File | CustomFile | string | null;
  wrapTemplateSelected: number | null;
  recipientInfo: OrderInterfaces.RecipientInfo;
  userName: string;
  country: string | undefined;
  countryId: string | undefined;
  isAllowedCountry: boolean;
  refreshTimestamp: number;
  network: FirebaseEnums.SupportedNetworks;
  confirmation: StarterEditionInterface.ConfirmationInfo;
  completion: StarterEditionInterface.Completion;
  timesLogged: number;
  lastTimePageReloaded: number;
  authWithEmailOnly: {
    email: string;
    validUser: boolean;
  },
  emailAndPasscodeLogin: boolean;
};

const initialState: StarterEditionState = {
  assetsERC20: [],
  assetERC721: [],
  stepper: {
    activeStep: 0,
    skipped: new Set<number>(),
    firstTimeNFTView: true,
    firstTimeWrapView: true,
  },
  erc721templateSelected: null,
  erc721File: null,
  mobileSelectedAssetsERC20: MOBILE_DEFAULT_ASSETS,
  wrapFile: null,
  wrapTemplateSelected: null,
  recipientInfo: DEFAULT_RECIPIENT_INFO,
  userName: '',
  country: '',
  countryId: '',
  isAllowedCountry: false,
  refreshTimestamp: 0,
  network: FirebaseEnums.SupportedNetworks.Polygon,
  confirmation: DEFAULT_CONFIRMATION_INFO,
  completion: DEFAULT_COMPLETION,
  timesLogged: 0,
  lastTimePageReloaded: 0,
  authWithEmailOnly: DEFAULT_AUTH_WITH_EMAIL,
  emailAndPasscodeLogin: false,
};



const slice = createSlice({
  name: 'starterEdition',
  initialState,
  reducers: {
    setERC20Assets: (state, action: PayloadAction<{ assetsERC20: OrderInterfaces.AssetERC20[] }>) => {
      state.assetsERC20 = action.payload.assetsERC20;
    },
    updateAssetQuantity: (state, action: PayloadAction<{ id: number, quantity: number }>) => {
      state.assetsERC20.map((asset) => {
        if (asset.id === action.payload.id) {
          // eslint-disable-next-line no-restricted-globals
          if (action.payload.quantity < 0 || isNaN(action.payload.quantity)) {
            asset.productAmount = 0;
            asset.giftoinAmount = 0;
            asset.valueUSD = 0;
          } else {
            asset.productAmount = action.payload.quantity;
            asset.giftoinAmount = action.payload.quantity;
            asset.valueUSD = Math.round((action.payload.quantity * asset.conversion.coinToUSD) * 100) / 100;
          };
        };
        return asset;
      });
    },
    updateAssetPrice: (state, action: PayloadAction<{ id: number, amount: number }>) => {
      state.assetsERC20.map((asset) => {
        if (asset.id === action.payload.id) {
          // eslint-disable-next-line no-restricted-globals
          if (action.payload.amount < 0 || isNaN(action.payload.amount)) {
            asset.productAmount = 0;
            asset.giftoinAmount = 0;
            asset.valueUSD = 0;
          } else {
            const convertedAmount = parseFloat((action.payload.amount / asset.conversion.coinToUSD).toFixed(7));

            asset.productAmount = convertedAmount;
            asset.giftoinAmount = convertedAmount;
            asset.valueUSD = action.payload.amount;
          };
        };
        return asset;
      });
    },
    setERC721: (state, action: PayloadAction<{ assetERC721: OrderInterfaces.AssetERC721 | undefined }>) => {
      state.assetERC721 = action.payload.assetERC721 ? [action.payload.assetERC721] : [];
    },
    setERC721File: (state, action: PayloadAction<{ file: File | CustomFile | string | null }>) => {
      state.erc721File = action.payload.file;
    },
    resetAssetsTransferred: (state) => {
      state.assetsERC20 = [];
      state.assetERC721 = [];
    },
    incrementActiveStep: (state) => {

      if (state.stepper.activeStep + 1 === StarterEditionEnums.Steps.CONFIRMATION
        && (!validateEmail(state.recipientInfo.recipientEmail) || !state.recipientInfo.recipientName)) {

        console.log('info step');
        return;
      };

      state.stepper.activeStep += 1;

      // first time going through view steps
      if (state.stepper.firstTimeNFTView && state.stepper.activeStep === StarterEditionEnums.Steps.ERC721) {
        state.stepper.firstTimeNFTView = false; // update to not first time view
        state.erc721templateSelected = 0; // select the default image id
        state.assetERC721 = [{ // add the default asset 
          name: 'Giftoin Starter Edition',
          description: 'Create by Giftoin',
          image: _mock_.NFT_TEMPLATES[0].url
        }];
      } else if (state.stepper.firstTimeWrapView && state.stepper.activeStep === StarterEditionEnums.Steps.WRAP) {
        state.stepper.firstTimeWrapView = false;
        state.wrapTemplateSelected = 0;
        state.wrapFile = _mock_.WRAP_TEMPLATES[0].url;
      }
    },
    decrementActiveStep: (state) => {
      if (state.stepper.activeStep <= 0) {
        state.stepper.activeStep = 0;
      } else {
        state.stepper.activeStep -= 1;
      }
    },
    resetStep: (state) => {
      state.stepper.activeStep = 0;
      state.completion = DEFAULT_COMPLETION;
    },
    setERC721TemplateSelected: (state, action: PayloadAction<{ currentIndex: number | null }>) => {
      state.erc721templateSelected = action.payload.currentIndex;
    },
    setMobileSelectedAssetsERC20: (state, action: PayloadAction<{ value: string[] }>) => {
      state.mobileSelectedAssetsERC20 = action.payload.value;
    },
    setWrap: (state, action: PayloadAction<{ file: File | CustomFile | string | null }>) => {
      state.wrapFile = action.payload.file;
    },
    setWrapTemplateSelected: (state, action: PayloadAction<{ currentIndex: number | null }>) => {
      state.wrapTemplateSelected = action.payload.currentIndex;
    },
    setRecipientName: (state, action: PayloadAction<{ value: string }>) => {
      state.recipientInfo.recipientName = action.payload.value;
    },
    setRecipientEmail: (state, action: PayloadAction<{ value: string }>) => {
      state.recipientInfo.recipientEmail = action.payload.value;
    },
    setGreeting: (state, action: PayloadAction<{ value: string }>) => {
      state.recipientInfo.greeting = action.payload.value;
    },
    setMessage: (state, action: PayloadAction<{ value: string }>) => {
      state.recipientInfo.message = action.payload.value;
    },
    setUserName: (state, action: PayloadAction<{ value: string }>) => {
      state.userName = action.payload.value;
    },
    setCountry: (state, action: PayloadAction<{ value: string | undefined }>) => {
      state.country = action.payload.value;
    },
    setCountryId: (state, action: PayloadAction<{ id: string | undefined }>) => {
      state.countryId = action.payload.id;
    },
    setIsAllowedCountry: (state, action: PayloadAction<{ isAllowed: boolean }>) => {
      state.isAllowedCountry = action.payload.isAllowed;
    },
    setRefreshTimestamp: (state, action: PayloadAction<{ timestamp: number }>) => {
      state.refreshTimestamp = action.payload.timestamp;
    },
    setNetwork: (state, action: PayloadAction<{ network: FirebaseEnums.SupportedNetworks }>) => {
      state.network = action.payload.network;
    },
    setName: (state, action: PayloadAction<{ name: string }>) => {
      state.confirmation.name = action.payload.name;
    },
    setTosChecked: (state, action: PayloadAction<{ checked: boolean }>) => {
      state.confirmation.tosChecked = action.payload.checked;
    },
    setLoggedIn: (state, action: PayloadAction<{ loggedIn: boolean }>) => {
      state.confirmation.loggedIn = action.payload.loggedIn;
    },
    setEmail: (state, action: PayloadAction<{ email: string }>) => {
      state.confirmation.email = action.payload.email;
    },
    setHolderId: (state, action: PayloadAction<{ id: string }>) => {
      state.confirmation.holderId = action.payload.id;
    },
    resetView: (state) => {
      state.assetERC721 = [];
      state.erc721templateSelected = null;
      state.erc721File = null;
      state.mobileSelectedAssetsERC20 = [];
      state.wrapFile = null;
      state.wrapTemplateSelected = null;
      state.recipientInfo = DEFAULT_RECIPIENT_INFO;
      state.userName = '';
      state.network = FirebaseEnums.SupportedNetworks.Polygon;
      state.confirmation = DEFAULT_CONFIRMATION_INFO;
    },
    setCompletionOrderId: (state, action: PayloadAction<{ orderId: string }>) => {
      state.completion.orderId = action.payload.orderId;
    },
    setOrderStatus: (state, action: PayloadAction<{ orderStatus: StarterEditionEnums.Status }>) => {
      state.completion.orderStatus = action.payload.orderStatus;
    },
    setLoading: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.completion.loading = action.payload.loading;
    },
    setRemoveProductView: (state) => {
      state.completion.removeProductOverview = true;
    },
    restCompletionStep: (state) => {
      state.completion = DEFAULT_COMPLETION;
    },
    incrementNumOfSync: (state) => {
      if (state.completion.numOfSync >= MAX_NUM_OF_SYNC) {
        state.completion.error = true;
        return;
      };
      state.completion.numOfSync += 1;
    },
    setErrorCompletion: (state, action: PayloadAction<{ isError: boolean }>) => {
      state.completion.error = action.payload.isError;
    },
    setUnauthorizedCountryTosChecked: (state, action: PayloadAction<{ checked: boolean }>) => {
      state.confirmation.unauthorizedCountryTosChecked = action.payload.checked;
    },
    setActiveStep: (state, action: PayloadAction<{ step: number }>) => {
      if (action.payload.step >= StarterEditionEnums.Steps.ERC20 && action.payload.step <= StarterEditionEnums.Steps.CONFIRMATION) {
        state.stepper.activeStep = action.payload.step;
      };
    },
    incrementOverallSyncs: (state) => {
      if (state.completion.overallSyncs >= MAX_OVERALL_SYNCS) {
        state.completion.error = true;
        return;
      };
      state.completion.overallSyncs += 1;
    },
    setShared: (state, action: PayloadAction<{ shared: boolean }>) => {
      state.completion.shared = action.payload.shared;
    },
    incrementTimesLogged: (state) => {
      state.timesLogged += 1;
    },
    setLastTimePageReloaded: (state, action: PayloadAction<{ date: number }>) => {
      state.lastTimePageReloaded = action.payload.date;
    },
    setAuthEmail: (state, action: PayloadAction<{ emailAddress: string }>) => {
      state.authWithEmailOnly.email = action.payload.emailAddress;
    },
    setAuthEmailValidUser: (state, action: PayloadAction<{ valid: boolean }>) => {
      state.authWithEmailOnly.validUser = action.payload.valid;
    },
    setLogoutAuthEmail: (state) => {
      state.authWithEmailOnly = DEFAULT_AUTH_WITH_EMAIL;
    },
    setEmailAndPasscodeLogin: (state, action: PayloadAction<{ emailAndPasscodeLogin: boolean }>) => {
      state.emailAndPasscodeLogin = action.payload.emailAndPasscodeLogin;
    },
  }
});


export const {
  setERC20Assets,
  setERC721,
  setERC721File,
  resetAssetsTransferred,
  updateAssetQuantity,
  updateAssetPrice,
  incrementActiveStep,
  decrementActiveStep,
  resetStep,
  setERC721TemplateSelected,
  setMobileSelectedAssetsERC20,
  setWrap,
  setWrapTemplateSelected,
  setRecipientName,
  setRecipientEmail,
  setGreeting,
  setMessage,
  setUserName,
  setCountry,
  setCountryId,
  setIsAllowedCountry,
  setRefreshTimestamp,
  setNetwork,
  setName,
  setTosChecked,
  setLoggedIn,
  setEmail,
  setHolderId,
  resetView,
  setCompletionOrderId,
  setOrderStatus,
  setLoading,
  setRemoveProductView,
  restCompletionStep,
  incrementNumOfSync,
  setErrorCompletion,
  setUnauthorizedCountryTosChecked,
  setActiveStep,
  incrementOverallSyncs,
  setShared,
  incrementTimesLogged,
  setLastTimePageReloaded,
  setAuthEmail,
  setAuthEmailValidUser,
  setLogoutAuthEmail,
  setEmailAndPasscodeLogin,
} = slice.actions;

export default slice.reducer;

