import { countries } from "src/assets/data";


export const validateEmail = (email: string) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );


export const validateEmails = (emails: string[]) => {
  const validatedEmails = emails.filter((email) => validateEmail(email));

  return validatedEmails;
};


/**
 * checks if country allowed to sell erc20 tokens
 * 
 * @returns boolean, string
 */
export const isCountryAllowed = async () => {
  try {
    const request = await fetch(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`);
    const response = await request.json();

    const allowedCountries = JSON.parse(process.env.REACT_APP_ALLOWED_COUNTRIES_WHITE_LIST as string);

    return { allowed: allowedCountries.indexOf(response.country) > -1, countryId: response.country };
  } catch (error) {
    console.log(error);
    return { allowed: false, countryId: '' };
  }
};
