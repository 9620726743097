// @mui
import { Stack, Typography, Divider } from '@mui/material';
import AuthWithSocial from './AuthWithSocial';
import AuthWithEmailAndPasscode from './AuthWithEmailAndPasscode';
// layouts
//

// ----------------------------------------------------------------------

export default function Login() {

  return (
    // <Stack sx={{ height: '100%', alignItems: 'center', display: 'flex' }}>
      // <Box component="img" src="../logo/giftoin_logo_long_dark.svg" alt="logo" sx={{ width: '100px', height: '40px' }} />
      <Stack spacing={2} sx={{ width: 320, height: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Stack direction='column' spacing={0} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='h4'>Welcome to</Typography>
          <Typography variant='h4'>Giftoin starter edition</Typography>
          <Typography variant='subtitle1' color='primary'>(BETA)</Typography>
        </Stack>
        <AuthWithEmailAndPasscode/>
        <Divider sx={{ width: '100%', fontSize: '13px', color: (theme) => theme.palette.grey[600] }}>OR</Divider>
        <AuthWithSocial sx={{ width: '100%' }} size='large' text='Continue With Google' />
      </Stack>
    // </Stack>
  );
};
