import { createSlice, Dispatch } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createUserWithEmailAndPassword, EmailAuthProvider, getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, User } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { FIREBASE_API } from 'src/config-global';
import localStorageAvailable from 'src/utils/localStorageAvailable';
// eslint-disable-next-line import/no-cycle
import { userAuth } from 'src/api/userAuth';
import { isValidToken, setSession } from 'src/auth/utils';
import { extractStatusObjectFromReduxResponse } from 'src/utils/errors';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import { RootState, store } from '../store';
import { setHolderId } from './starterEditionSlice';

interface AuthState {
    isAuthenticatedWeb2: boolean;
    isAuthenticatedWeb3: boolean;
    isInitialized: boolean;
    user: null | Record<string, any>;
    balance: {
        fiatInDollar: number;
        giftoins: number;
    };
}

const initialState: AuthState = {
    isAuthenticatedWeb2: false,
    isAuthenticatedWeb3: false,
    isInitialized: false,
    user: null,
    balance: {
        fiatInDollar: 0,
        giftoins: 0
    },
}

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        initialize(state, action: PayloadAction<{
            isAuthenticatedWeb2?: boolean;
            isAuthenticatedWeb3?: boolean;
            user?: {} | null;
        }>) {
            state.isInitialized = true;
            state.isAuthenticatedWeb2 = action.payload.isAuthenticatedWeb2 ?? false;
            state.isAuthenticatedWeb3 = action.payload.isAuthenticatedWeb3 ?? false;
            state.user = action.payload.user ?? null;
        },
        web2Login(state, action: PayloadAction<{ user: {} }>) {
            const { user } = action.payload;
            state.isAuthenticatedWeb2 = true;
            state.user = user;
            state.isInitialized = true;
        },
        web2Logout(state, action) {
            state.isAuthenticatedWeb2 = false;
            state.isInitialized = true;
            state.user = null;
        },
        initializeBalance(state, action: PayloadAction<{ fiatInDollar: number, giftoins: number }>) {
            state.balance.fiatInDollar = action.payload.fiatInDollar;
            state.balance.giftoins = action.payload.giftoins;
        },
    },
})

// Actions
export const { web2Login, web2Logout, initialize, initializeBalance } = slice.actions


// ----------------------------------------------------------------------
// Firebase Authentication

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const GOOGLE_PROVIDER = new GoogleAuthProvider();
const EMAIL_PROVIDER = new EmailAuthProvider();

// ----------------------------------------------------------------------


// Utils functions

/**
 * Send API request to server to validate user login
 *  TODO: Remake this function as hook
 * * Error: handle error and throw Error(errorMessage) to be shown to user
 * * Success: handle response and save user data
 * @param firebaseUser user object fetched from firebase/auth package
 */
export async function login(firebaseUser: User) {

    if (firebaseUser) {
        const firebaseIdToken = await firebaseUser.getIdToken();
        const emailAddress = firebaseUser.email ?? "";

        // Attempt to login with user credentials and fetch its data
        const result: any = await store.dispatch(userAuth.endpoints.login.initiate({ emailAddress }));
        if (result.error) {
            const errorStatus = extractStatusObjectFromReduxResponse(result.error);
            if (errorStatus.errorCode === 500) {
                throw new Error("Communication error with server - please try again later");
            };
            return false;
            throw new Error(errorStatus.errorMessage);
        };
        // Save access token in local storage & initiate timer for current session
        setSession(firebaseIdToken);

        store.dispatch(setHolderId({ id: result.data.holderId }));

        // !Using the store dispatch instead of using dispatch variable like the other external functions because its now working for now - gives an error

        store.dispatch(slice.actions.web2Login({ user: firebaseUser }));
        return true;

    }

    return false;

}



// External slice functions - need to use dispatch(function())

/**
 * Check if user is already connected by:
 * * Access Token: Check if there is a saved and valid access token in local storage -> attempt to use it for session
 * * Firebase User: Check if we have access to firebase/auth local user -> attempt to login with server
 */
export function checkIfUserIsConnected() {
    const storageAvailable = localStorageAvailable();
    return async (dispatch: Dispatch) => {
        try {
            /**
             * If access token found we wait until the login process is finished before setting the isInitialize to true
             * 
             * Show loading screen until finished authenticate user
             */
            let accessTokenFound = false;
            const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
            if (accessToken && isValidToken(accessToken)) {
                await onAuthStateChanged(AUTH, async (firebaseUser) => {
                    const { isAuthenticatedWeb2 } = (store.getState() as RootState).auth;
                    if (!isAuthenticatedWeb2) {
                        if (firebaseUser) {
                            const res = await login(firebaseUser);
                            if (!res) {
                                // Failed to login with server - only set the auth to initialized = true
                                dispatch(initialize({}))
                            }
                        }
                        
                    }
                    
                });
                accessTokenFound = true;
            }

            // remove after we have idtoken for user
            // const { isAuthenticatedWeb2 } = (store.getState() as RootState).auth;

            // if (isAuthenticatedWeb2) {
            //     console.log('heyaaaa');
            //     return;
            // };
            

            if (!accessTokenFound) {
                console.log('dwagef');
                dispatch(slice.actions.initialize({}));

            }


        } catch (error) {
            dispatch(slice.actions.initialize({}));
            console.log(error);
        }

    };
}


export function loginWithEmailAndPassword(email: string, password: string) {
    return async (dispatch: Dispatch) => {
        // const response = await axios.post('/api/account/login', {
        //     email,
        //     password,
        // });
        // const { accessToken, user } = response.data;

        // setSession(accessToken);
        alert("need to implement login with email and password")
    }
}


// export function registerWithEmailAndPassword(email: string, password: string, walletAddress: string) {
//     return async (dispatch: Dispatch) => {
//         try {

//             // Register in google auth
//             const userCredential = await createUserWithEmailAndPassword(AUTH, email, password);
//             const { user } = userCredential;

//             // Register in our database using backend api call
//             // !Implement in new server studio service
//             const createCreatorRes = await userAuth.endpoints.register.initiate({
//                 walletAddress,
//                 name: ''
//             })





//             dispatch(slice.actions.web2Login({ user, walletAddress }))
//             setSession(await user.getIdToken());

//         } catch (error) {
//             const errorCode = error.code;
//             const errorMessage = error.message;
//             alert(errorMessage);
//         }
//     }

// }


export function logout() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.web2Logout({}));
        // Reset creator slice data
        setSession(null);
        AUTH.signOut();
    }
}


// Reducer
export default slice.reducer


// Selectors


