import { m } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Button, Container, Link, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { PageNotFoundIllustration } from '../assets/illustrations';

// ----------------------------------------------------------------------

export default function PageError() {
  return (
    <>
      <Helmet>
        <title>Error, Page Not Found</title>
      </Helmet>

      <Container maxWidth="xl" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100%' }}>

        <MotionContainer sx={{ textAlign: 'center' }}>

          <m.div variants={varBounce().in} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <img alt='giftoin_logo' src="https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2FLogo%203.png?alt=media&token=95a0e2ba-1d81-4794-aeb6-8adad72758c3" width={125} height={125} />
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              Sorry, page not found!
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
              sure to check your spelling.
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <PageNotFoundIllustration
              sx={{
                height: 260,
                my: { xs: 5, sm: 10 },
              }}
            />
          </m.div>

          <Link href="https://giftoin.com/">
          <Button size="large" variant="contained" sx={{textTransform: 'inherit'}}>
            Giftoin.com
          </Button>
          </Link>
        </MotionContainer>
      </Container>
    </>
  );
}
