import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Container, Link, Stack, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Iconify from '../../components/iconify';
// sections
// assets
import { EmailInboxIcon } from '../../assets/icons';
import AuthVerifyCodeForm from "../../sections/auth/AuthVerifyCodeForm";
import Page404 from '../Page404';

// ----------------------------------------------------------------------

export default function VerifyCodePage() {
  const location = useLocation();

  if (!location.state || !(location.state.email as string)) {
    return (
      <Container sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', textAlign: 'center' }}>
        <Page404 />
      </Container>
    );
  };

  return (
    <>
      <Helmet>
        <title> Verify Code</title>
      </Helmet>

      <Stack sx={{ width: '100%', height: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>

        <Stack spacing={0} sx={{ width: { xs: '100%', sm: 500 }, height: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>

          <EmailInboxIcon sx={{ height: 96 }} />

          <Typography variant="h3" paragraph>
            Please check your email!
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 5 }}>
            We have emailed a 6-digit confirmation code to acb@domain, please enter the code in below
            box to verify your email.
          </Typography>

          <AuthVerifyCodeForm />

          <Typography variant="body2" sx={{ my: 3 }}>
            Don’t have a code? &nbsp;
            <Link variant="subtitle2">Resend code</Link>
          </Typography>

          <Link
            component={RouterLink}
            to={PATH_AUTH.login}
            color="inherit"
            variant="subtitle2"
            sx={{
              mx: 'auto',
              alignItems: 'center',
              display: 'inline-flex',
            }}
          >
            <Iconify icon="eva:chevron-left-fill" width={16} />
            Return to sign in
          </Link>
        </Stack>

      </Stack>
    </>
  );
}
