
export enum SupportedNetworks {
  Mumbai = "mumbai",
  Goerli = "goerli",
  Polygon = "polygon",
  Ethereum = 'ethereum',
};

export enum AssetTransferStatus {
  Pending = "Pending",
  InProgress = "In Progress",
  Delivered = "Delivered",
  Error = "Error"
};