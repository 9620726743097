/* eslint-disable import/no-cycle */

import { configureStore } from '@reduxjs/toolkit';
import {
    persistReducer,
    persistStore,
} from 'redux-persist'
import {
    TypedUseSelectorHook,
    useDispatch,
    useSelector,
} from 'react-redux';

import { baseWebServer } from 'src/api/baseWebServer';
import rootReducer, { rootPersistConfig } from './rootReducer';

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }).concat([baseWebServer.middleware]),

})
const persistor = persistStore(store);

const { dispatch } = store;

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const useAppDispatch = () => useDispatch<AppDispatch>();

export { store, persistor, dispatch, useAppSelector, useAppDispatch };

