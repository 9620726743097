import { useAppDispatch, store, useAppSelector } from "src/redux/store";
import { orderApi } from 'src/api/orderApi';
import { incrementNumOfSync, incrementOverallSyncs, setErrorCompletion, setLoading, setOrderStatus } from "src/redux/slices/starterEditionSlice";
import { StarterEditionEnums } from 'src/types/starter-edition';
import { OrderEnums } from "src/types/order";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { userAuth } from "src/api/userAuth";


export const useValidateDomain = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const validateDomain = async (resellerId: string, domainURL: string) => {

    try {
      setIsLoading(true);

      const valid = await dispatch(userAuth.endpoints.validateDomain.initiate({ resellerId, domainURL }, { forceRefetch: true })) as unknown as { data: boolean; };
      setResponse(valid.data);

      setIsLoading(false);

    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  return { validateDomain, isLoading, response };
};

