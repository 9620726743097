// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------
// redux
import { PersistGate } from 'redux-persist/integration/react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { store, persistor } from './redux/store';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import SnackbarProvider from './components/snackbar';
import ScrollToTop from './components/scroll-to-top';
import { MotionLazyContainer } from './components/animate';
import { ThemeSettings, SettingsProvider } from './components/settings';
// eslint-disable-next-line import/no-named-as-default
import AuthLayer from './auth/AuthLayer';
import { StyledChart } from './components/chart';
import PageError from './pages/PageError';
import { useValidateDomain } from './hooks/auth/validateDomain';


// ----------------------------------------------------------------------

export default function App() {
  const [loading, setLoading] = useState(true);
  const [iframe, setIframe] = useState<{ isIframe: boolean; href: string }>({ isIframe: false, href: '' });

  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <AuthProvider> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>

            <SettingsProvider>
              <BrowserRouter>
                <ScrollToTop />
                <MotionLazyContainer>
                  <ThemeProvider>
                    <ThemeSettings>
                      <ThemeLocalization>
                        <SnackbarProvider>
                          <AuthLayer>
                            <StyledChart />
                              <Router />
                          </AuthLayer>
                        </SnackbarProvider>
                      </ThemeLocalization>
                    </ThemeSettings>
                  </ThemeProvider>
                </MotionLazyContainer>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
          {/* </AuthProvider> */}
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  );
};
