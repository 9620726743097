import { FirebaseInterfaces } from 'src/types/firebase';
// eslint-disable-next-line import/no-cycle
import { baseWebServer } from './baseWebServer';


export enum UserType {
  Holder = "holder",
  Reseller = "reseller",
  Creator = "creator",
};

const BASE_URL = 'api/app';

export const userAuth = baseWebServer.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<boolean, { emailAddress: string }>({
      query: (args) => ({
        url: `/auth/login`,
        method: 'POST',
        body: { ...args },
      }),
      invalidatesTags: ['User'],
      transformResponse: (baseQueryReturnValue: FirebaseInterfaces.ReturnObject,
        meta: unknown,
        arg: unknown,
      ) => baseQueryReturnValue.data as boolean,
    }),
    loginNew: builder.mutation<{ giftoins: number; fiatInDollar: number; }, { email: string; passcode: string; }>({
      query: (args) => ({
        url: `/auth/login-new/${process.env.REACT_APP_RESELLER_ID}`,
        method: 'POST',
        body: { ...args },
      }),
      invalidatesTags: ['User'],
      transformResponse: (baseQueryReturnValue: FirebaseInterfaces.ReturnObject,
        meta: unknown,
        arg: unknown,
      ) => baseQueryReturnValue.data as { giftoins: number; fiatInDollar: number; },
    }),
    validateDomain: builder.query<object, { resellerId: string, domainURL: string }>({
      query: (args) => ({
        url: `/auth/validate-domain`,
        method: 'GET',
        params: { ...args }
      }),
      providesTags: ['Product'],
      transformResponse: (baseQueryReturnValue: FirebaseInterfaces.ReturnObject,
        meta: unknown,
        arg: unknown,
      ) => baseQueryReturnValue.data as object,
      keepUnusedDataFor: 0,
    }),
    validateUserRole: builder.query<object, { emailAddress: string, userType: UserType }>({
      query: (args) => ({
        url: `/auth/validate-user-role`,
        method: 'GET',
        params: { ...args }
      }),
      providesTags: ['User'],
      transformResponse: (baseQueryReturnValue: FirebaseInterfaces.ReturnObject,
        meta: unknown,
        arg: unknown,
      ) => baseQueryReturnValue.data as object,
      keepUnusedDataFor: 0,
    }),
  }),
})
