/* eslint-disable consistent-return */
import { createSlice, PayloadAction, Update } from "@reduxjs/toolkit";



interface UserState {
  showMockData: boolean;
};

const initialState: UserState = {
  showMockData: true
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateShowMockData: (state, action: PayloadAction<boolean>) => {
      state.showMockData = action.payload;
    },
  }
});


export const {
  updateShowMockData,
} = slice.actions;

export default slice.reducer;



