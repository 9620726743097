

export enum RefreshAction {
  RESET,
  INCREMENT_TIME,
  DECREMENT_TIME,
};

export enum Steps {
  ERC20 = 0,
  ERC721 = 1,
  WRAP = 2,
  INFO = 3,
  CONFIRMATION = 4,
};

export enum Status {
  InProgress = 'In Progress',
  Error = 'Error',
  Delivered = 'Delivered',
};

export enum UploadView {
  Wrap = 'wrap',
  GreetingNFT = 'nft',
};