/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { baseWebServer } from 'src/api/baseWebServer';
import { persistReducer } from 'redux-persist';
import userSlice from './slices/userSlice';
import authSlice from './slices/authSlice';
import starterEditionSlice from './slices/starterEditionSlice';
// slices

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  version: 1,
  keyPrefix: 'redux-',
};
export const userPersistConfig = {
  key: 'root',
  storage,
  version: 1,
  keyPrefix: 'redux-',
};
export const starterEditionPersistConfig = {
  key: 'root',
  storage,
  version: 1,
  keyPrefix: 'redux-',
};


const userPersistedReducer = persistReducer(userPersistConfig, userSlice);
const starterEditionPersistedReducer = persistReducer(starterEditionPersistConfig, starterEditionSlice);

const rootReducer = combineReducers({
  starterEdition: starterEditionPersistedReducer,
  auth: authSlice,
  user: userPersistedReducer,
  [baseWebServer.reducerPath]: baseWebServer.reducer,
});

export default rootReducer;
