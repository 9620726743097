import { Helmet } from 'react-helmet-async';
// sections
import { Box, Container } from '@mui/material';
import Login from '../../sections/auth/Login';

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> Login</title>
      </Helmet>

      <Container maxWidth="xl" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100%' }}>
        <Login />
      </Container>
    </>


  );
}
