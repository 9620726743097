import { CircularProgress, Container } from '@mui/material';
import React, { FC, useEffect, useState } from 'react'
import { useValidateDomain } from 'src/hooks/auth/validateDomain';
import PageError from 'src/pages/PageError';
import { useAppDispatch } from 'src/redux/hooks';
import { checkIfUserIsConnected } from 'src/redux/slices/authSlice';

type AuthLayerProps = {
  children: React.ReactNode;
};

/**
 * Initialize the auth slice 
 * 
 * Detect if the user is already logged in - update the redux slice accordingly:
 * * Detect wallet
 * * Detect firebase auth user
 * 
 * Initiate wallet listeners
 * * When user changes network
 * * When user disconnect changes account on metamask
 * 
 */
export function AuthLayer({ children }: AuthLayerProps) {
  const [loading, setLoading] = useState(true);
  const [iframe, setIframe] = useState<{ isIframe: boolean; href: string }>({ isIframe: false, href: '' });

  const { validateDomain, isLoading, response } = useValidateDomain();

  function inIframe() {
    try {
      // is iframe
      if (window.self !== window.top) {
        if (document.referrer) {
          setIframe({ isIframe: true, href: document.referrer });
          return true;
        };
        if (document.location.href) {
          setIframe({ isIframe: true, href: document.location.href });
          return true;
        };
        setIframe({ isIframe: true, href: '' });
        return true;
      }

      setIframe({
        isIframe: false, href: window.location.href
      });

      return false;
    } catch (e) {
      return false;
    };
  };

  useEffect(() => {
    inIframe();
  }, []);

  useEffect(() => {
    console.log(iframe);

    const validate = async () => {
      await validateDomain('123', iframe.href);
    };

    validate();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframe]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(checkIfUserIsConnected());
  }, [dispatch]);

  console.log(loading);

  if (loading || isLoading) {
    return (
      <Container sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <CircularProgress />
      </Container>
    );
  };


  return <>{response ? children : <PageError />}</>;

  // return <>{children}</>



};

export default AuthLayer