import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { FIREBASE_API } from "src/config-global";
// eslint-disable-next-line import/no-cycle
import { store } from "src/redux/store";

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);


export const prepareHeaders = async (headers: Headers, { getState }: { getState: any }) => {


    const token = await AUTH.currentUser?.getIdToken();

    const { email, validUser } = store.getState().starterEdition.authWithEmailOnly;

    if (token) {
        headers.set('x-giftoin-id-token', token)
    } else if (email && validUser) {
        headers.set('x-giftoin-id-token', email)
    } else {
        console.log("no token available")
    }
    return headers
}
