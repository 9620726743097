

const NFT_TEMPLATES = [
  {
    alt: 'img1',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fnft-templates%2FGiftoin%20-%20Starter%20Studio%20Greetings%20(Cards)%20-%20AP.jpg?alt=media&token=12ca6861-7aef-4822-94fd-6b17abfc7a12',
  },
  {
    alt: 'img2',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fnft-templates%2FThank%20You%20-%20Animated%20Greeting.gif.gif?alt=media&token=c4202be4-3c0c-48ef-81bd-81bfaf1e2e1b',
  },
  {
    alt: 'img3',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fnft-templates%2FCongrats%20-%20Animated%20Greeting.gif?alt=media&token=e8b5c981-c675-4060-ba10-2ae90e0288b3',
  },
  {
    alt: 'img4',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fnft-templates%2FLove%20You%20-%20Animated%20Greeting.gif.gif?alt=media&token=ce2c8301-2004-4fdd-ae92-01ea0ff07ac6',
  },
  {
    alt: 'img5',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fnft-templates%2FNice%20Job%20-%20Animated%20Greeting.gif.gif?alt=media&token=b11b9779-1666-4b28-beba-797a61a860e6',
  },
  {
    alt: 'img6',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fnft-templates%2F1%20-%20Starter%20Studio%20Greetings%20(Cards)%20-%20AP.jpg?alt=media&token=ac282b61-9c67-4956-8e82-e9a5db4c49cf',
  },
  {
    alt: 'img7',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fnft-templates%2F2%20-%20Starter%20Studio%20Greetings%20(Cards)%20-%20AP.jpg?alt=media&token=15a71098-ed24-47a3-bd88-ce431bb9b79d',
  },
  {
    alt: 'img8',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fnft-templates%2F3%20-%20Starter%20Studio%20Greetings%20(Cards)%20-%20AP.jpg?alt=media&token=10b95791-5ae2-44ae-9ec9-a792c04bbc2d',
  },
  {
    alt: 'img9',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fnft-templates%2F4%20-%20Starter%20Studio%20Greetings%20(Cards)%20-%20AP.jpg?alt=media&token=d5c3112f-9801-4e53-b790-25798cc7f8c0',
  },
  {
    alt: 'img10',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fnft-templates%2F5%20-%20Starter%20Studio%20Greetings%20(Cards)%20-%20AP.jpg?alt=media&token=34e97320-7747-46d3-b248-c2afb8d11d45',
  },
];


const WRAP_TEMPLATES = [
  {
    alt: 'img1',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fwrap-templates%2FAnimated%20Pattern%202%20-%20By%20AvishayP.gif?alt=media&token=341578be-d73b-49f9-8fee-f04baf5dd7ee',
  },
  {
    alt: 'img2',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fwrap-templates%2FAnimated%20Pattern%206%20-%20By%20AvishayP.gif?alt=media&token=cfdc9bcb-0a48-489e-8403-b40c6d5d3358',
  },
  {
    alt: 'img3',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fwrap-templates%2FAnimated%20Pattern%203%20-%20By%20AvishayP.gif?alt=media&token=e8aa71ac-7d88-4c97-8c7c-d70ee0a003f6',
  },
  {
    alt: 'img4',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fwrap-templates%2FAnimated%20Pattern%207.5%20-%20By%20AvishayP.gif?alt=media&token=1b6b34bc-d37c-4d36-a790-88707f497c03',
  },
  {
    alt: 'img5',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fwrap-templates%2FAnimated%20Pattern%205%20-%20By%20AvishayP.gif?alt=media&token=3c9d6e22-5fbe-4809-bbe7-05e33d724a68',
  },
  {
    alt: 'img6',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fwrap-templates%2FCyber%20Pattern%201%20-%20AP.jpg?alt=media&token=f84e0f99-ca62-439d-b724-e304a41a1d20',
  },
  {
    alt: 'img7',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fwrap-templates%2FCyber%20Pattern%202%20-%20AP.jpg?alt=media&token=852a5e20-ad98-4913-8d92-6c37292c93ea',
  },
  {
    alt: 'img8',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fwrap-templates%2FHearts%20Pattern%201%20-%20AP.jpg?alt=media&token=c816aec0-531a-4579-9a18-62158a7222f0',
  },
  {
    alt: 'img9',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fwrap-templates%2FHearts%20Pattern%202%20-%20AP.jpg?alt=media&token=ef627c86-abc4-4a26-aeba-1c81465cd7ed',
  },
  {
    alt: 'img11',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fwrap-templates%2FHappy%20Pattern%201%20-%20AP.jpg?alt=media&token=93a219af-c280-4822-bf19-b07d4b3cdaca',
  },
  {
    alt: 'img12',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fwrap-templates%2FCyber%20Pattern%204%20-%20AP.jpg?alt=media&token=43aaf595-8d10-435e-8d4e-9fa6b9724ff2',
  },
  {
    alt: 'img13',
    url: 'https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/starter-edition%2Fwrap-templates%2FCyber%20Pattern%203%20-%20AP.jpg?alt=media&token=3095ab4b-e60b-490e-aac3-9af278bbc29a',
  },
];


const _mock_ = {
  NFT_TEMPLATES,
  WRAP_TEMPLATES,
};

export default _mock_;