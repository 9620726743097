import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useSnackbar } from "notistack"
import { FIREBASE_API } from "src/config-global";
import { initializeBalance, login } from "src/redux/slices/authSlice";
import { store, useAppDispatch } from "src/redux/store";
import { useDispatch } from 'react-redux';
import { setAuthEmail, setAuthEmailValidUser, setEmail, setLoggedIn, setName, setUserName } from "src/redux/slices/starterEditionSlice";
import { useState } from "react";
import { extractStatusObjectFromReduxResponse } from "src/utils/errors";
import { userAuth } from "src/api/userAuth";

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const GOOGLE_PROVIDER = new GoogleAuthProvider();


export default function useLoginWithGoogle() {
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();

  const loginWithGoogle = async () => {
    try {
      setIsLoading(true);
      const { user: firebaseUser } = await signInWithPopup(AUTH, GOOGLE_PROVIDER);

      if (!firebaseUser.email) {
        return false;
      };

      const result: any = await appDispatch(userAuth.endpoints.loginNew.initiate({ email: firebaseUser.email, passcode: '123456' }));

      console.log(result);

      if (result.error) {
        const errorStatus = extractStatusObjectFromReduxResponse(result.error);
        if (errorStatus.errorCode === 500) {
          throw new Error("Communication error with server - please try again later.");
          return false;
        };
        if (result.error.status === 404) {
          // throw new Error("User not in whitelist.");
          enqueueSnackbar(`User not in whitelist.`, { variant: 'warning', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })
          return false;
        };
      };

      if (firebaseUser) {
        dispatch(setLoggedIn({ loggedIn: true }));
        dispatch(setName({ name: firebaseUser.displayName ?? firebaseUser.email ?? '' }));
        dispatch(setUserName({ value: firebaseUser.displayName ?? '' }));
        dispatch(setEmail({ email: firebaseUser.email ?? '' }));
      };
      
      // initializing user balance 
      if (result.data.fiatInDollar >= 0 && result.data.giftoins >= 0) {
        const balance = result.data as { fiatInDollar: number; giftoins: number; };

        appDispatch(initializeBalance(balance));
      };
      
      const isSuccess = await login(firebaseUser);

      if (!isSuccess) {
        enqueueSnackbar(`User not in whitelist`, { variant: 'warning' })
      };

      setIsLoading(false);
    }
    catch (error) {
      enqueueSnackbar(`Failed to login -${error.message} `, { variant: 'error' })
      setIsLoading(false);
    };

    return false;
  };

  return { loginWithGoogle, isLoading }
};